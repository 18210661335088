<template>
  <div>
    <div class="headerDetail">
      <ds-header :title="headerTitle"></ds-header>
      <a-button type="primary" @click="back">返回</a-button>
    </div>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row>
        <a-col :span="span">
          <a-form-model-item label="标题" prop="title">
            <a-input v-model="form.title" :maxLength="20" placeholder="请输入标题(20字)" class="width400"
              :disabled="disabled" />
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="静态页图片" prop="imgUrl">
            <a-upload list-type="picture-card" class="avatar-uploader" :action="IMG_API + '/oss/files'"
              :before-upload="beforeUpload" @change="handleImgChange" accept=".png,.jpg,.jpeg" :disabled="disabled"
              @preview="handlePreview" :remove="handleRemove" :file-list="fileList">
              <div v-if="!fileList.length">
                <a-icon type="plus" />
              </div>

            </a-upload>
            <p style="line-height: 20px;">
              注:
              支持jpg、png、jpeg格式的图片
            </p>
          </a-form-model-item>
        </a-col>
        <a-col :span="span" v-if="$route.query.type === 'look'">
          <a-form-model-item label="H5链接">
            <div>
              <span>{{ h5Url }}</span>
              <a-button type="link" @click="copyH5url">复制</a-button>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="footer" v-if="!disabled">
      <a-button key="submit" style="margin-right: 30px" type="primary" @click="onSubmit">
        保存
      </a-button>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false" width="350px">
      <div style="margin-top: 20px;">
        <div class="flat-patternmaking-bg">
          <img :src="'https://oss-workplace-prod.innoecos.cn/picture-prod/08a1bf301bc10000_phone-bg.png'" class="bg" alt="">
          <div class="container">
            <div class="navigation-view">{{ form.title }}</div>
            <div class="content">
              <img alt="example" style="width: 100%; z-index: 10;" :src="form.imgUrl" />
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { IMG_API } from "@/config";
import * as api from '@/api/staticPageManagement/index'
export default {
  computed: {
    headerTitle() {
      let text = '';
      if (this.$route.query.type === 'add') {
        text = '新增';
      } else if (this.$route.query.type === 'edit') {
        text = '编辑';
      } else if (this.$route.query.type === 'look') {
        text = '查看';
      }
      return `${text}静态页面`
    },
    disabled() {
      return this.$route.query.type === 'look';
    }
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      span: 13,
      loading: false,
      previewVisible: false,
      IMG_API,
      fileList: [],
      h5Url: '',
      form: {
        title: '',
        imgUrl: ''
      },
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
        ],
        imgUrl: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
      },
      phoneBg: require('@/assets/phone-bg.png')
    };
  },

  methods: {
    back() {
      this.$router.back();
    },
    /**网络请求 */
    // 添加静态页
    async addStaticPage() {
      try {
        const res = await api.addStaticPage(this.form);
        if (res.code === this.$SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.back();
        } else {
          this.$message.warning(res.msg);
        }
      } catch (error) {

      }
    },
    // 获取静态页详情
    async getDetail() {
      try {
        const res = await api.getStaticPageDetail({ id: this.$route.query.id });
        if (res.code === this.$SUCCESS_CODE) {
          this.form = {
            title: res.data.title,
            imgUrl: res.data.imgUrl,
            id: res.data.id
          };
          this.fileList = [
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: res.data.imgUrl
            }
          ];
          this.h5Url = res.data.h5Url;
        }
      } catch (error) {

      }
    },
    // 编辑静态页
    async editStaticPage() {
      try {
        const res = await api.editStaticPage(this.form);
        if (res.code === this.$SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.back();
        } else {
          this.$message.warning(res.msg);
        }
      } catch (error) {

      }
    },
    // 新版封面图片上传
    handleImgChange(info) {
      this.fileList = info.fileList;
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.loading = false;
        this.form.imgUrl = info.file.response.redirect_uri
      }
    },
    // 新版封面图片限制
    beforeUpload(file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、png格式");
        return Promise.reject();
      }
      return isJpgOrPng;
    },
    handlePreview() {
      this.previewVisible = true;
    },
    handleRemove() {
      this.form.imgUrl = '';
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.$route.query.type === 'add') {
            this.addStaticPage();
          } else {
            this.editStaticPage();
          }
        }
      });
    },
    async copyH5url() {
      try {
        navigator.clipboard.writeText(this.h5Url);
        this.$message.success('复制成功')
      } catch (error) {

      }

    },
  },
  mounted() {
    if (this.$route.query.type !== 'add') {
      this.getDetail();
    }
  },
};
</script>

<style lang="scss" scoped>
.headerDetail {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.width400 {
  width: 400px !important;
}

.footer {
  margin-left: 200px;
  margin-top: 50px;
}
.flat-patternmaking-bg {
  width: 290px;
  height: 569px;
  position: relative;
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .container {
    position: absolute;
    left: 16px;
    top: 40px;
    width: 259px;
    height: calc(100% - 53px);
    z-index: 10;
    .navigation-view {
      height: 31px;
      width: 100%;
      text-align: center;
      line-height: 31px;
      font-size: 14px;
      font-weight: bold;
      position: absolute;
      left: 0;
      top: 0;
    }
    .content {
      position: absolute;
      left: 0;
      top: 31px;
      height: calc(100% - 33px);
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      border-radius: 26px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background-color: rgb(38, 38, 38);
    }
  }
  .content::-webkit-scrollbar {
    width: 0 !important;
  }
}
::v-deep .ant-modal-content {
  box-shadow: none !important;
  background-color: #ffffff00;
}
</style>