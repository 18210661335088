import { fetchApi } from '@/utils/axios';
import URL from '@/api/urlConfig';

/**
 * 获取静态页列表
 * @param {String} params.title 标题
 * @param {Number} params.pageNo 页码
 * @param {Number} params.pageSize 数量
 * @returns
 */
export const getStaticPageList = (params) => fetchApi(URL.GET_STATIC_PAGE_LIST, params);
/**
 * 添加静态页
 * @param {String} data.title 标题
 * @param {String} data.imgUrl 图片URL
 * @returns
 */
export const addStaticPage = (data) => fetchApi(URL.ADD_STATIC_PAGE, data, 'post', 'data');

/**
 * 获取静态页详情
 * @param {String} data.id 静态页id
 * @returns
 */
export const getStaticPageDetail = (params) => fetchApi(URL.GET_STATIC_PAGE_DETAIL, params);

/**
 * 编辑静态页
 * @param {String} data.id id
 * @param {String} data.title 标题
 * @param {String} data.imgUrl 图片URL
 * @returns
 */
export const editStaticPage = (data) => fetchApi(URL.EDIT_STATIC_PAGE, data, 'put', 'data');

/**
 * 删除静态页
 * @param {String} params.id id
 * @returns
 */
export const deleteStaticPage = (params) => fetchApi(`${URL.DELETE_STATIC_PAGE}?id=${params.id}`, null, 'delete');
